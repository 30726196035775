$font-family-base: 'Quicksand', sans-serif;

// Branding colors
$blue: hsl(215deg, 60%, 40%);
$black: hsl(315deg, 7%, 11%);
$white: hsl(132deg, 9%, 89%);
$gold: hsl(35deg, 85%, 32%);
$green: hsl(126deg, 36%, 22%);
$purple: hsl(305deg, 24%, 26%);
$red: hsl(358deg, 51%, 37%);

$theme-colors: (
  primary: $blue,
  dark: $black,
  light: $white
);


@import "../node_modules/bootstrap/scss/bootstrap.scss";
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,700&amp;subset=latin-ext');

body {
  overflow-x: hidden;
}

body[data-menu="true"] {
  overflow-y: hidden;
}

#navbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;

  #logo {
    svg {
      height: 4rem;
      width: auto;
    }
  }

  #menu-toggle, label {
    display: none;
  }

  .icon-close {
    display: none;
  }

  .nav-link {
    svg {
      display: none;
    }
  }

  @include media-breakpoint-down(sm) {

    label {
      display: block;
      z-index: 2;

      svg {
        height: 2em;
        width: auto;
      }
    }

    #logo {
      z-index: 2;
    }

    #menu {
      --transition-duration: 0.5s;
      width: 100%;
      overflow: hidden;
      visibility: hidden;
      font-size: 1.2rem;
      display: flex;
      flex-wrap: nowrap;
      overflow-y: auto; // FIXME: On very short viewports will overlap with the logo
      flex-direction: column;
      justify-content: top;
      padding-top: 8rem;
      background: $red;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: 1;
      opacity: 0%;
      transition:
        visibility 0s var(--transition-duration),
        opacity var(--transition-duration),
        color var(--transition-duration)
      ;

      li {
        width: 100%;
        max-width: 540px;
        margin: 0 auto;
      }

      .nav-link {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        font-weight: 500;

        &:focus {
          background: $gold;
          font-weight: 600;
        }

        svg {
          display: block;
          height: 1.5em;
          width: 1.5em;
        }
      }
    }

    #menu-toggle:checked {
      ~ * {
        color: $white;
      }

      ~ #menu {
        visibility: visible;
        opacity: 100%;
        transition:
          opacity var(--transition-duration)
        ;
      }

      ~ .icon-close {
        display: block;
      }

      ~ .icon-open {
        display: none;
      }

    }
  }

}


#navbar .nav-item {
  font-weight: bold;
}

#diagram svg {
  height: 100%;
}

a[href]:not(.btn) {
  color: inherit;
  text-decoration: underline;
}

#content p {
  hyphens: auto;
}

h2 {
  font-size: 1.25rem;
  font-weight: 800;
  margin-bottom: 2rem;
  border-bottom: solid;
  margin-top: 4rem;
}

h3 {
  font-size: 1.25rem;
  font-weight: 500;
}

h4 {
  font-size: 1rem;
  font-weight: 800;
}

p {
  hyphens: auto;
}

.colorful {
  color: $white;
  margin-left: calc(-100vw / 2 + 50%);
  margin-right: calc(-100vw / 2 + 50%);
  overflow: hidden;
  margin-bottom: 4rem;

  .container {
    padding: 1rem 0;
  }


  @include media-breakpoint-down(sm) {
    background:
      linear-gradient(1deg, transparent calc(63% - 1px), $red calc(63% + 1px)),
      linear-gradient(-2deg, transparent calc(43% - 1px), $purple calc(43% + 1px)),
      linear-gradient(3deg, $green calc(25% - 1px), $gold calc(25% + 1px))
    ;
  }

  @include media-breakpoint-only(md) {

    $vertical-axis: 2deg;
    $horizontal-axis: 88deg;
    $blur: 2deg;

    background:
      conic-gradient(
        at 50% 60%,
        $red calc($vertical-axis - $blur),
        $purple calc($vertical-axis + $blur),
        $purple calc($horizontal-axis - $blur),
        $green calc($horizontal-axis + $blur),
        $green calc($vertical-axis + 180deg - $blur),
        $gold calc($vertical-axis + 180deg + $blur),
        $gold calc($horizontal-axis + 180deg - $blur),
        $red calc($horizontal-axis + 180deg + $blur)
      );
  }

  @include media-breakpoint-up(lg) {
    background: linear-gradient(90deg, $red 50%, $green 50%);

    .container {
      background:
        linear-gradient(93deg, transparent calc(75% - 1px), $green calc(75% + 1px)),
        linear-gradient(87deg, transparent calc(50% - 1px), $gold calc(50% + 1px)),
        linear-gradient(95deg, transparent calc(26% - 1px), $purple calc(26% + 1px))
      ;
    }
  }
}

#members h3 {
  font-size: 1.2rem;
}

.bg-red {
  background: $red;
  color: $white;
}

.wider {
  max-width: calc(1140px * 1.2);
}

.narrow {
  max-width: 800px;
  margin: auto;
}

main img {
  max-width: 100%;
}

.hashtags {
  display: flex;
  flex-wrap: wrap;
  gap: .5rem;
  list-style: none;
  padding: 0;

  > li {
    font-weight: 500;
    font-size: 80%;

    &::before {
      content: "#";
    }
  }
}


.lead {
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 2rem;
  hyphens: none;
  font-weight: normal;
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.slogan {
  text-align: center;
  font-size: 1.5rem;
  line-height: 200%;
  hyphens: none;
  max-width: 800px;
  margin: auto;
  min-height: 20rem;
  font-size: 1.5rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.carousel {
  $fade-out-duration: 1s;

  font-size: .9rem;
  display: flex;
  overflow-x: hidden;
  overflow-y: visible;
  margin: 1rem auto;

  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }

  > * {
    display: block;
    text-decoration: none;
    width: 100%;
    flex-shrink: 0;
    opacity: 0%;
    transition:
      $fade-out-duration opacity 0s,
      0s margin-left $fade-out-duration
    ;

    &.current {
      transition:
        2s opacity $fade-out-duration,
        0s margin-left $fade-out-duration
      ;
      opacity: 100%;
    }
  }

}

ul.indicators {
  display: flex;
  gap: 1.5rem;
  justify-content: center;
  margin: 0;
  padding: 0;
  opacity: 0.3;
  margin-top: 1rem;

  li {
    list-style: none;

    &::before {
      content: "○";
    }

    &.current::before {
      content: "●";
    }
  }
}

video.mobile {
  width: 100%;
}

.banner {
  background-image: url(../assets/banner.jpg);
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: cover;

  --webkit-text-stroke: 2px white;
}
